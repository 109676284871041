<template>
  <div class="">
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        @input="onNavInput"
        temporary
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-divider></v-divider>
            <v-card-text class="pt-2">
              <v-subheader class="pl-1 pb-2">Налаштування складу сім’ї</v-subheader>
              <div class="mb-3">
                <v-btn small depressed color="grey lighten-3" @click="addNewRow" class="mr-2">
                  <v-icon left size="22">
                    mdi-plus
                  </v-icon>
                  Додати рядок
                </v-btn>
              </div>
              <div class="table elevation-1">
                <div class="table-row">
                  <div class="table-col"></div>
                  <div class="table-col">П.І.Б</div>
                  <div class="table-col">Відношення</div>
                  <div class="table-col">Дата народження</div>
                  <div class="table-col">Паспорт</div>
                </div>
                <div
                    class="table-row"
                    v-for="(item, idx) in persons_local"
                    :key="`tr-${idx}`"
                >
                  <div class="table-col">
                    <v-checkbox v-model="item.selected" :ripple="false" hide-details class="mt-0 pt-0"
                                color="success"></v-checkbox>
                    <v-icon :disabled="!item.new" color="error lighten-1" @click="deleteRow(item.row_num)">mdi-delete
                    </v-icon>
                  </div>
                  <div class="table-col">
                    <input type="text" v-model="item.full_name">
                  </div>
                  <div class="table-col">
                    <input type="text" v-model="item.relation_name">
                  </div>
                  <div class="table-col">
                    <input type="text" :value="item.date_of_birth | formatDate" readonly>
                    <DateComponentIcon v-model="item.date_of_birth" :row_num="item.row_num" @changeDate="changeDate"/>
                  </div>
                  <div class="table-col">
                    <input type="text" v-model="item.passport">
                  </div>
                </div>
              </div>
              <v-textarea
                  hide-details
                  solo
                  color="grey"
                  label="Додаткові дані"
                  v-model="additionalDetails"
                  class="mt-4"
                  rows="2"
              />
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="pt-2">
              <v-subheader class="pl-1">Налаштування підписів</v-subheader>
              <div class="table table-1 elevation-1">
                <div class="table-row">
                  <div class="table-col"></div>
                  <div class="table-col">П.І.Б</div>
                  <div class="table-col">Ініціали</div>
                  <div class="table-col">Посада</div>
                </div>
                <div
                    class="table-row"
                    v-for="(item, idx) in local_response_people"
                    :key="`tr-${idx}`"
                >
                  <div class="table-col">
                    <v-checkbox v-model="item.selected" :ripple="false" hide-details class="mt-0 pt-0"
                                color="success"></v-checkbox>
                    <v-icon :disabled="!item.new" color="error lighten-1" @click="deleteRow(item.row_num)">mdi-delete
                    </v-icon>
                  </div>
                  <div class="table-col">
                    <input type="text" v-model="item.full_name">
                  </div>
                  <div class="table-col">
                    <input type="text" v-model="item.short_name">
                  </div>
                  <div class="table-col">
                    <input type="text" v-model="item.position">
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="report-section" style="padding-top: 2px">
      <div class="rp-col-4 rp-col-border rp-text-center rp-pa-14">
        <div class="rp-row">
          {{ print_data.organization_details_without_account }}
        </div>
        <div class="rp-row mt-2">
          {{ `${formatDate(current_date, 'DD MMMM YYYY')} року №____` }}
        </div>
      </div>
      <div class="rp-col-4"></div>
      <div class="rp-col-4 rp-text-right">
        <div class="rp-row">
          ЗАТВЕРДЖЕННО <br>
          Наказ Міністерства праці та <br>
          соціальної політики України <br>
          22.07.2003 №204
        </div>
      </div>
    </div>
    <div class="report-empty-row" style="height: 60px"/>
    <div class="report-section">
      <div class="rp-col-12">
        <div class="rp-row rp-text-center">
          <h2>Довідка про склад сім’ї</h2>
          <h2>або зареєстрованих у житловому приміщенні будинку, осіб</h2>
        </div>
      </div>
    </div>
    <div class="report-empty-row" style="height: 40px"/>
    <div class="report-section" style="font-size: 14px">
      <div class="rp-col-12">
        <div class="rp-row">
          {{
            `Видана гр.${local_person.full_name} (${formatDate(local_person.date_of_birth)} р.н. ), паспорт - ${local_person.passport || ''}`
          }}
        </div>
        <div class="rp-row">
          {{ `адреса: ${flat.full_address} про те, що до складу сім’ї (зареєстрованих) входять:` }}
        </div>
      </div>
    </div>
    <div class="report-empty-row" style="height: 20px"></div>
    <div class="report-section">
      <div class="report-table">
        <div class="report-table-row rp-font-weight-medium">
          <div class="rp-col-5">
            <span class="table-col-content">Прізвище, ім’я та по-батькові</span>
          </div>
          <div class="rp-col-2">
            <span class="table-col-content">Родинні<br> відносини</span>
          </div>
          <div class="rp-col-2">
            <span class="table-col-content">Дата<br> народження</span>
          </div>
          <div class="rp-col-3">
            <span class="table-col-content">Паспорт<br> або свідоцтво</span>
          </div>
        </div>
        <div
            class="report-table-row"
            v-for="(person, index) in persons_local.filter(item => item.selected)"
            :key="`tr-${index}`"
        >
          <div class="rp-col-5">
            <span class="table-col-content">{{ person.full_name }}</span>
          </div>
          <div class="rp-col-2">
            <span class="table-col-content">{{ person.relation_name }}</span>
          </div>
          <div class="rp-col-2">
            <span class="table-col-content">{{ person.date_of_birth | formatDate }}</span>
          </div>
          <div class="rp-col-3">
            <span class="table-col-content">{{ person.passport || person.birth_certificate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="report-section">
      <div class="rp-row">
        {{ additionalDetails }}
      </div>
    </div>
    <div class="report-empty-row" style="height: 20px"/>
    <div class="report-section" style="font-size: 14px">
      <div class="rp-col-12">
        <div class="rp-row">
          {{ `Усього: ${persons_local.length}` }}
        </div>
        <div class="rp-row">
          {{ `Тип власності: ${getFlatType(flat.flat_type)}` }}
        </div>
      </div>
    </div>
    <div class="report-empty-row" style="height: 20px"/>
    <div class="report-section" style="font-size: 13px">
      <div class="rp-col-12">
        <div class="rp-row rp-font-weight-medium">
          Довідка видана для пред’явлення до фонду праці та соціального захисту населення
        </div>
        <div class="rp-row">
          {{ `Довідка видана згідно даних, які на даний час є в ${organization.short_name}` }}
        </div>
      </div>
    </div>
    <div class="report-empty-row" style="height: 20px"/>
    <div class="report-section-column">
      <div
          class="report-table-row"
          v-for="(item_response, idx_response) in local_response_people.filter(item => item.selected)"
          :key="`rsp-${idx_response}`"
      >
        <div class="rp-col-4 rp-text-right">
          <span class="table-col-content-right table-col-down">{{ item_response.position }}</span>
        </div>
        <div class="rp-col-4" style="border-bottom: 1px solid grey"></div>
        <div class="rp-col-4">
          <span class="table-col-content-left table-col-down">{{ item_response.short_name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {getFlatType} from '@/utils/icons'
import {formatDate} from "@/filters";
import reportAPI from "@/utils/axios/reports"
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  name: "certificate_204",
  props: ['persons', 'organization', 'print_data', 'person', 'global_settings', 'generate_xlsx', 'generate_email', 'email_address'],
  components: {
    DateComponentIcon: () => import('@/components/tableComponent/DateComponentIcon')
  },
  computed: {
    ...mapGetters({
      flat: 'getFlat'
    }),
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    }
  },
  data() {
    return {
      setting_dialog: this.global_settings,
      persons_local: this.persons,
      current_date: this.getCurrentDate(),
      local_person: this.persons.find(item => item.person_hash === this.person),
      person_watcher: null,
      settings_watcher: null,
      generate_xlsx_watcher: null,
      generate_email_watcher: null,
      local_response_people: this.print_data.response_people.slice(),
      additionalDetails: '',
      registration: false
    }
  },
  methods: {
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    formatDate,
    getFlatType,
    getCurrentDate() {
      const date = new Date()
      const year = date.getUTCFullYear()
      const month = date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1
      const day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()
      return `${year}-${month}-${day}`
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    changeDate(payload) {
      if (payload) {
        const row = this.persons_local.find(item => item.row_num === payload.row_num)
        if (row) {
          row.date_of_birth = payload.value
        }
      }
    },
    addNewRow() {
      this.persons_local.push({
        row_num: this.persons_local.length + 1,
        full_name: '',
        person_hash: this.persons_local.length + 1,
        relation_name: '',
        date_of_birth: '',
        passport: '',
        birth_certificate: '',
        selected: false,
        new: true
      })
    },
    deleteRow(row_num) {
      const tab_item = this.persons_local.find(tab_item => tab_item.row_num === row_num)
      if (tab_item) {
        const idx = this.persons_local.indexOf(tab_item)
        this.persons_local.splice(idx, 1)
      }
      this.persons_local.forEach((item, idx) => {
        item['row_num'] = idx + 1
      })
    },
    generate_xlsx_report() {
      if (this.person) {
        this.$emit('xlsx_loading_status', true)
        const payload = {person_hash: this.flat.person_hash, person: this.person}
        const people_data = this.persons_local.filter(i => i.selected).map(i => {
          return {
            full_name: i.full_name,
            relation_name: i.relation_name,
            date_of_birth: i.date_of_birth,
            birth_certificate: i.birth_certificate,
            passport: i.passport
          }
        })
        const response_people_data = this.local_response_people.filter(i => i.selected).map(i => {
          return {
            full_name: i.full_name,
            short_name: i.short_name,
            position_name: i.position
          }
        })
        payload['report_data'] = {
          people: people_data,
          response_people: response_people_data,
          additional_details: this.additionalDetails,
          registration: this.registration
        }

        reportAPI.certificate_204_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `certificate_204${formatDate(this.date_start, 'MM_YYYY')}_${formatDate(this.date_end, 'MM_YYYY')}_${this.person}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.$emit('xlsx_loading_status', false)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('xlsx_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('onGenerateXlsx', null)
            })
      }
    },
    generate_email_send() {
      if (this.person) {
        this.$emit('email_loading_status', true)
        const payload = {person_hash: this.flat.person_hash, person: this.person, email: this.email_address}
        const people_data = this.persons_local.filter(i => i.selected).map(i => {
          return {
            full_name: i.full_name,
            relation_name: i.relation_name,
            date_of_birth: i.date_of_birth,
            birth_certificate: i.birth_certificate,
            passport: i.passport
          }
        })
        const response_people_data = this.local_response_people.filter(i => i.selected).map(i => {
          return {
            full_name: i.full_name,
            short_name: i.short_name,
            position_name: i.position
          }
        })
        payload['report_data'] = {
          people: people_data,
          response_people: response_people_data,
          additional_details: this.additionalDetails,
          registration: this.registration
        }
        reportAPI.certificate_204_email(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {color: data.status === 'OK' ? 'success' : 'error', text: data.text})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('onGenerateEmail')
            })
      }
    },
    watch_person() {
      this.watcher = this.$watch(
          'person',
          {
            immediate: true,
            handler(payload) {
              this.local_person = this.persons.find(item => item.person_hash === payload)
            }
          }
      )
    },
    watch_settings() {
      this.watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    watch_generate_xlsx() {
      this.generate_xlsx_watcher = this.$watch(
          'generate_xlsx',
          {
            handler(payload) {
              if (payload !== null) {
                this.generate_xlsx_report()
              }
            }
          }
      )
    },
    watch_generate_email() {
      this.generate_email_watcher = this.$watch(
          'generate_email',
          {
            handler(payload) {
              if (payload) {
                this.generate_email_send()
              }
            }
          }
      )
    },

  },
  created() {
    this.watch_person()
    this.watch_settings()
    this.watch_generate_xlsx()
    this.watch_generate_email()
  },
  beforeDestroy() {
    if (this.person_watcher) {
      this.person_watcher()
    }
    if (this.settings_watcher) {
      this.settings_watcher()
    }
    if (this.generate_xlsx_watcher) {
      this.generate_xlsx_watcher()
    }
    if (this.generate_email_watcher) {
      this.generate_email_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
$border-color: #c1c1c1;
.table-1 {
  .table-row {
    .table-col {
      &:nth-child(1) {
        flex: 0 0 80px !important;
      }

      &:nth-child(2) {
        flex: 0 0 50% !important;
      }

      &:nth-child(3) {
        flex: 0 0 25% !important;
      }

      &:nth-child(4) {
        flex: 1 !important;
      }
    }
  }
}

.report-table {
  .report-table-row {
    &:nth-child(1) {
      border-top: 1px solid $border-color;
    }

    & > div {
      border-right: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
    }

    & > div:nth-child(1) {
      border-left: 1px solid $border-color;
    }
  }
}

.table {
  .table-row {
    display: flex;
    color: #2d2d2d;
    font-size: .82rem;

    &:first-child {
      background-color: #fafafa;
      font-size: .72rem;
      font-weight: 500;
    }

    &:last-child {
      .table-col {
        border-bottom: thin solid $border-color;
      }
    }

    .table-col {
      border-top: thin solid $border-color;
      padding: 4px 8px;
      display: flex;

      input {
        outline: none;
        width: 100%;
        display: flex;
      }


      &:nth-child(1) {
        flex: 0 0 80px;
        border-left: thin solid $border-color;
        border-right: thin solid $border-color;
      }

      &:nth-child(2) {
        flex: 0 0 35%;
        border-right: thin solid $border-color;
      }

      &:nth-child(3) {
        flex: 0 0 20%;
        border-right: thin solid $border-color;
      }

      &:nth-child(4) {
        flex: 0 0 20%;
        border-right: thin solid $border-color;
      }

      &:nth-child(5) {
        flex: 1;
        border-right: thin solid $border-color;
      }
    }
  }
}

</style>